import { useState } from 'react';

import { WORKPLACE_AVAILABILITY_RANGES } from '../constants/capacityRanges.constants';
import { WorkplaceAvailabilityStatus } from '../models/WorkplaceAvailabilityStatus.type';


export default function useWorkplaceAvailability(availablePlacesCount: number, totalCount: number) {
    const [count, setCount] = useState(availablePlacesCount);
    
    function getCapacityStatus(availablePlacesCount: number): WorkplaceAvailabilityStatus {
        const percentageAvailable = (availablePlacesCount / totalCount) * 100;

        return WORKPLACE_AVAILABILITY_RANGES
            .find(({ range }) => {
                return percentageAvailable >= range[0] && percentageAvailable <= range[1];
            })
            ?.status || 'high';
    }

    return {
        value: count,
        capacityStatus: getCapacityStatus(count),
        setValue: (newValue: number) => setCount(newValue)
    }
};