import React from 'react';
import { Card } from '@material-ui/core';

import { ArrowForward } from '@material-ui/icons';

import './DashboardBanner.scss';

const DashboardBanner: React.FunctionComponent = () => {
    return (
        <Card 
            classes={{ 
                root: "rampup-dashboard-banner" 
            }}>

            <article>
                <p>
                    <span>
                        <ArrowForward></ArrowForward> Please avoid rush hour time,<br/>
                        check out the current restaurant occupancy
                    </span>
                </p>
            </article>
        </Card>
    );
}

export default DashboardBanner;