

import { useState, useMemo } from 'react';

import RestaurantListTableData from './RestaurantListTableData.interface';
import SiteRestaurantData from '../models/SiteRestaurantData.interface';

export default function useRestaurantListTableData(data: SiteRestaurantData[]) {
    const [originalData, setData] = useState<SiteRestaurantData[]>(data);
    const rows = useMemo<RestaurantListTableData[]>(() => {
        return extendWithOccupancyPercentages(originalData);
    }, [originalData]);

    function getPercentage(capacity: number, count: number): number {
        if (!capacity) { return null; }

        return Math.round((count / capacity) * 100);
    }

    function notNegative(value: number): number {
        return value > 0 ? value : 0;
    }

    function extendWithOccupancyPercentages(data: SiteRestaurantData[]): RestaurantListTableData[] {
        return data
            .map(row => {
                const { restaurantOccupancy, restaurantCapacity } = row;

                return {
                    ...row,
                    occupancyPercentage: getPercentage(restaurantCapacity, restaurantOccupancy),
                    available: notNegative(row.restaurantCapacity - row.restaurantOccupancy)
                };
            });
    }

    return {
        rows,
        setData
    };
}
