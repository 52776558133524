import React, { useEffect, useRef } from 'react';

import './CurrentOccupancy.scss';
import useBuildingCapacity from '../../../uiToolkit/useBuildingCapacity';
import HistoricalOccupancyRecord from '../../../models/HistoricalOccupancyRecord.interface';
import useOccupancyTrend from '../../../uiToolkit/useOccupancyTrend';
import TrendArrow from '../TrendArrow/TrendArrow';

interface CurrentOccupancyProps {
    value: number;
    historicalData: HistoricalOccupancyRecord[];
}

const CurrentOccupancy: React.FunctionComponent<CurrentOccupancyProps> = props => {
    const { value, capacityStatus, setValue } = useBuildingCapacity(props.value);
    const { value: trendValue, setValue: setTrendValue } = useOccupancyTrend(props.historicalData);
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) {
            setValue(props.value);
            setTrendValue(props.historicalData);
        } else {
            didMount.current = true;
        }
    }, [props.value, props.historicalData])

    return (
        <div className="resto-current-occupancy">
            <div className="resto-current-occupancy-label">Current occupancy</div>
            <div className={`resto-current-occupancy-value ${capacityStatus}`}>
                {value}%
            </div>
            <TrendArrow status={capacityStatus} trend={trendValue}/>
        </div>
    );
};

export default CurrentOccupancy;