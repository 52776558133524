import React, { useEffect } from 'react';

import TableColumn from '../../uiToolkit/RampUpTable/TableColumn.interface';
import useRestaurantOccupancyTableData from './useRestaurantOccupancyTableData';
import RestaurantOccupancyData from '../../models/RestaurantOccupancyData.interface';
import useSortableData from '../../uiToolkit/RampUpTable/useSortableData';
import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import RampUpTableHeader from '../../uiToolkit/RampUpTable/RampUpTableHeader/RampUpTableHeader';

import './RestaurantOccupancyTable.scss';
import RestaurantOccupancyTableRow from './RestaurantOccupancyTableRow/RestaurantOccupancyTableRow';

interface RestaurantOccupancyTableProps {
    data: RestaurantOccupancyData[];
    redirectToSite?: boolean;
    showMasterDetailView?: boolean;
}

const RestaurantOccupancyTable: React.FunctionComponent<RestaurantOccupancyTableProps> = (props) => {
    const keyColumn = 'restaurantName';
    const masterDetailViewEnabled = props.showMasterDetailView !== false;
    const columns: TableColumn[] = [
        {
            id: 'restaurantName',
            type: 'siteCanteenLink',
            caption: 'Restaurant',
            headerIcon: 'FastFood'
        },
        {
            id: 'capacityPercentage',
            type: 'capacityPercentage',
            caption: 'Occupancy',
            align: 'right',
            headerIcon: 'Group'
        },
        {
            id: 'available',
            type: 'availablePlacesCount',
            caption: 'Available seats',
            align: 'right',
            headerIcon: 'EventSeat'
        }
    ];

    if (masterDetailViewEnabled) {
        columns.unshift({ caption: '', id: '', type: 'toggleRow', allowSorting: false });
    }

    const { rows, setData } = useRestaurantOccupancyTableData(props.data);
    const { items, sortConfig, setItems, doSort } = useSortableData([], null, columns);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    useEffect(() => {
        setItems(rows);
    }, [rows]);

    return (
        <div className="rampup-dashboard-table">
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <RampUpTableHeader
                        columns={columns}
                        orderBy={sortConfig?.sortBy}
                        sortOrder={sortConfig?.sortOrder}
                        onApplySorting={e => doSort(e)}>
                    </RampUpTableHeader>
                    <TableBody>
                        {items.map((row) => (
                            <RestaurantOccupancyTableRow
                                key={row[keyColumn]}
                                rowKey={row[keyColumn]}
                                columns={columns}
                                data={{...row}}
                                masterDetailViewEnabled={masterDetailViewEnabled}>
                            </RestaurantOccupancyTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default RestaurantOccupancyTable;