import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography, CircularProgress, Link, withStyles } from '@material-ui/core';
import { OptionsObject, useSnackbar } from 'notistack';

import './RestoDashboard.scss';
import { getSitesAndRestaurants, getRestaurantAggregatedData } from '../api/server';
import DashboardBanner from './DashboardBanner/DashboardBanner';
import RestaurantsOccupancyData from '../models/RestaurantOccupancyData.interface';
import RestoBar from '../RestoBar/RestoBar';
import useInterval from '../common/useInterval';
import RestaurantOccupancyTable from './RestaurantOccupancyTable/RestaurantOccupancyTable';
import SiteDetailsData from '../models/SiteDetailsData.interface';
import {GA} from "../analytics/GA";


const DashboardCircularProgress = withStyles({
    root: {marginTop: '2em'}
})(CircularProgress);

interface RestoDashboardProps {
    showMasterDetailView?: boolean;
}

const RestoDashboard = (props: RestoDashboardProps) => {
    const [sites, setSites] = useState<{ site: SiteDetailsData, restaurants: RestaurantsOccupancyData[] }[]>([]);
    const [dateLastRefreshed, setDateLastRefreshed] = useState<Date>(null);
    const {enqueueSnackbar} = useSnackbar();
    const [columnSize, setColumnSize] = useState<3 | 4 | 6 | 12>(6);

    const showMasterDetailView = props?.showMasterDetailView;
    const errorMessage = 'Failed to fetch dashboard data!';
    const errorDetails: OptionsObject = {
        variant: 'error',
        anchorOrigin: {
            horizontal: 'center',
            vertical: 'top'
        }
    }

    const assignColumnSize = (numberOfSites: number) => {
        switch(numberOfSites){
            case 1: setColumnSize(12);
                break;
            case 2: setColumnSize(6);
                break;
            case 3: setColumnSize(4);
                break;
            default: setColumnSize(3);
        }
    }

    const updateData = () => {
        getSitesAndRestaurants()
            .then(data => {
                setSites(data);
                setDateLastRefreshed(new Date());
                assignColumnSize(data.length);
            })
            .catch(() => enqueueSnackbar(errorMessage, errorDetails));
    }

    useEffect(() => {
        updateData();
        GA.pageView("Resto main dashboard");
    }, []);

    useInterval(() => {
        updateData();
    }, 60000);

    return (
        <React.Fragment>
            <RestoBar dateLastRefreshed={dateLastRefreshed}/>
            <Container className="rampup-dashboard-wrapper">
                <DashboardBanner/>

                <div className="rampup-dashboard-tables">
                    { dateLastRefreshed && <Grid container spacing={2}>
                        {
                            sites.map(site => (
                                <Grid item xs={12} md={12} lg={columnSize} className="rampup-dashboard-table-wrapper" key={site.site.siteId}>
                                    <Typography 
                                        color="textPrimary" 
                                        variant="h5"
                                        component="h5"
                                        classes={{root: "rampup-section-header"}}>
                                        <Link href={'#/site/' + site.site.siteId}>{site.site.name}</Link>
                                    </Typography>
                                    { 
                                        <RestaurantOccupancyTable 
                                            data={site.restaurants} 
                                            redirectToSite={true}
                                            showMasterDetailView={showMasterDetailView}
                                        />}
                                </Grid>
                            ))
                        }
                    </Grid> }
                    {!dateLastRefreshed && <DashboardCircularProgress/>}
                </div>
            </Container>
        </React.Fragment>
    );
};

export default RestoDashboard;