import React, { useEffect } from 'react';
import useWorkplaceAvailability from '../useWorkplaceAvailability';

interface AvailablePlacesCountProps {
    count: number;
    totalCount: number;
    isClosed?: boolean;
}

const AvailablePlacesCount: React.FunctionComponent<AvailablePlacesCountProps> = (props) => {
    const { capacityStatus, setValue } = useWorkplaceAvailability(props.count, props.totalCount);

    useEffect(() => {
        setValue(props.count);
    }, [props.count]);

    if (props.isClosed) {
        return (<span 
            className={'resto-available-places resto-badge-closed'}>
            Closed today
        </span>);
    } else {
        return (<span 
            className={'resto-available-places resto-availability-level-' + capacityStatus}>
            {props.count}
        </span>);
    }
};

export default AvailablePlacesCount;
