import React from 'react';
import { Link } from '@material-ui/core';

import CapacityBar from '../../CapacityBar/CapacityBar';
import PeoplePerFloor from '../../PeopleCount/PeopleCount';
import AvailablePlacesCount from '../../AvailablePlacesCount/AvailablePlacesCount';

interface RampUpTableCellProps {
    rowData: { [columnId: string]: any };
    value: any;
    columnType: string;
}


function CellTemplate(props: RampUpTableCellProps) {
    const { value, columnType, rowData } = props;

    switch (columnType) {
        case 'capacityPercentage':
            return <CapacityBar value={value} occupancyHistory={rowData.occupancyHistory} isClosed={rowData.isClosed}></CapacityBar>;
        case 'peoplePerFloor':
            return <PeoplePerFloor capacity={rowData.floorCapacity} count={value}></PeoplePerFloor>;
        case 'peopleInRestaurant':
            return <PeoplePerFloor capacity={rowData.capacity} count={value}></PeoplePerFloor>;
        case 'peoplePerBuilding':
            return <PeoplePerFloor capacity={rowData.buildingCapacity} count={value}></PeoplePerFloor>;
        case 'availablePlacesCount':
            return <AvailablePlacesCount count={value} totalCount={rowData.capacity} isClosed={rowData.isClosed}></AvailablePlacesCount>;
        case 'siteCanteenLink':
            return (<React.Fragment>
                <Link href={'#/site/' + rowData.siteId}>{rowData.buildingCode} - {value}</Link>
            </React.Fragment>);
        case 'siteCanteen':
            return <React.Fragment>{rowData.buildingCode} - {value}</React.Fragment>;
        default:
            return <div>{value}</div>;
    }
}

const RampUpTableCell: React.FunctionComponent<RampUpTableCellProps> = (props) => {
    return <CellTemplate {...props}></CellTemplate>
}

export default RampUpTableCell;