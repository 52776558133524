import { useEffect, useRef } from 'react';

type IntervalCallback = () => void;

export default function useInterval(callback: IntervalCallback, delay: number) {
    const savedCallback = useRef<IntervalCallback>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (delay !== null) {
            let intervalId = setInterval(() => {
                savedCallback.current();
            }, delay);

            return () => clearInterval(intervalId);
        }
    }, [delay]);
}