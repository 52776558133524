import { useState, useMemo } from 'react';

import HistoricalOccupancyRecord from '../../models/HistoricalOccupancyRecord.interface';
import RestaurantOccupancyData from '../../models/RestaurantOccupancyData.interface';
import ChartDataPoint from '../../OccupancyChart/ChartDataPoint.class';

interface RestaurantOccupancyTableData {
    count: number;
    buildingCode: string;
    buildingId: number;
    capacity: number;
    capacityPercentage: number;
    restaurantName: string;
    siteId: number;
    available: number;
    occupancyHistory: HistoricalOccupancyRecord[];
    avgOccupancyHistory: ChartDataPoint[];
}

export default function useRestaurantOccupancyTableData(data: RestaurantOccupancyData[]) {
    const [originalData, setData] = useState<RestaurantOccupancyData[]>(data);
    const rows = useMemo<RestaurantOccupancyTableData[]>(() => {
        return getRestaurantOccupancyTableData(originalData);
    }, [originalData]);

    function getPercentage(capacity: number, count: number): number {
        if (!capacity) { return null; }

        return Math.round((count / capacity) * 100);
    }

    function notNegative(value: number): number {
        return value > 0 ? value : 0;
    }

    function getRestaurantOccupancyTableData(data: RestaurantOccupancyData[]): RestaurantOccupancyTableData[] {
        return data
            .map(row => {
                const {
                    capacity, occupancy, buildingCode, name, buildingId, siteId, occupancyHistory, avgOccupancyHistory, isClosed, message
                } = row;

                return { 
                    count: occupancy,
                    buildingCode,
                    buildingId,
                    capacity,
                    capacityPercentage: getPercentage(capacity, occupancy),
                    restaurantName: name,
                    siteId: siteId !== undefined ? siteId : null,
                    available: notNegative(capacity - occupancy),
                    occupancyHistory,
                    avgOccupancyHistory,
                    isClosed,
                    message
                };
            });
    }

    return {
        rows,
        setData
    };
}
