import moment from 'moment';

export default class ChartDataPoint {
    public x: Date;
    public y: number;

    static stripTimestampTimezone(timestamp: string): string {
        return timestamp.split('+')[0];
    }

    constructor(value: number, timestamp: string) {
        this.y = value > 0 ? value : 0;
        this.x = moment(
            ChartDataPoint.stripTimestampTimezone(timestamp)
        ).toDate();
    }
}