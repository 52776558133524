import React from 'react';
import { Grid, Paper } from '@material-ui/core';

import './SiteRestaurantList.scss';
import { RestaurantAggregatedData } from '../../models/RestaurantAggregatedData.interface';
import ChartDataPoint from '../../OccupancyChart/ChartDataPoint.class';
import SiteRestaurantData from '../../models/SiteRestaurantData.interface';
import SiteRestaurantOverview from '../SiteRestaurantOverview/SiteRestaurantOverview';

interface SiteRestaurantListProps {
    restaurants: SiteRestaurantData[];
    restaurantsAggregatedData: RestaurantAggregatedData[];
}

const SiteRestaurantList: React.FunctionComponent<SiteRestaurantListProps> = (props) => {
    const findRestaurantAggregatedData = (id: number): ChartDataPoint[] => {
        const restaurantData = props.restaurantsAggregatedData.find(data => data.cateringOutletId === id);

        return restaurantData ? restaurantData.avgOccupancy : [];
    };

    return (
        <Grid container spacing={0} classes={{ root: "rampup-site-grid-container rampup-site-restaurants-grid-container" }}>
            <Grid item xs={12}>
                {
                    props.restaurants
                        .map(restaurant => (
                            <Paper 
                                key={restaurant.restaurantName}
                                classes={{ root: "rampup-site-restaurants-details" }}>
                                <div className="rampup-site-restaurants-details-wrapper">
                                    <SiteRestaurantOverview
                                        name={restaurant.restaurantName}
                                        buildingCode={restaurant.buildingCode}
                                        floorName={restaurant.floorName}
                                        capacity={restaurant.restaurantCapacity}
                                        historicalData={restaurant.occupancyHistory}
                                        currentOccupancy={restaurant.restaurantOccupancy}
                                        weeklyAvgOccupancy={findRestaurantAggregatedData(restaurant.cateringOutletId)}
                                        isClosed={restaurant.isClosed}
                                        message={restaurant.message}
                                    />
                                </div>
                            </Paper>
                        ))
                }
            </Grid>
        </Grid>
    );
}

export default SiteRestaurantList;