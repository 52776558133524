import React, { useState } from 'react';
import { Collapse, IconButton, TableCell, TableRow } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import RampUpTableCell from '../../../uiToolkit/RampUpTable/RampUpTableCell/RampUpTableCell';
import TableColumn from '../../../uiToolkit/RampUpTable/TableColumn.interface';
import RestaurantOccupancyData from '../../../models/RestaurantOccupancyData.interface';
import OccupancyChart from '../../../OccupancyChart/OccupancyChart';
import {GA} from "../../../analytics/GA";

interface RestaurantOccupancyTableRowProps {
    rowKey: number;
    columns: TableColumn[];
    data: RestaurantOccupancyData;
    masterDetailViewEnabled: boolean;
}

const RestaurantOccupancyTableRow: React.FunctionComponent<RestaurantOccupancyTableRowProps> = (props) => {
    const [open, setOpen] = useState(false);

    function toggleOpen(open) {
        if (open) {
            GA.event("Resto", "Expand ", props.data.restaurantName);
        }
        setOpen(open);
    }

    return (
        <React.Fragment>
            <TableRow 
                className={open ? 'rampup-table-row-expanded' : 'rampup-table-row-collapsed'}
                key={props.rowKey} 
                onClick={() => props.masterDetailViewEnabled && toggleOpen(!open)}>
                {props.columns.map(column => (
                    <TableCell key={column.id} align={column.align || (column.type === 'number' ? 'right' : 'left')}>
                        { column.type !== 'toggleRow' && (
                            <RampUpTableCell 
                                value={props.data[column.id]}
                                columnType={column.type}
                                rowData={{...props.data}}>
                            </RampUpTableCell> 
                        )}
                        { column.type === 'toggleRow' && (
                            <IconButton 
                                aria-label="expand row" 
                                size="small" 
                                classes={{ root: 'rampup-toggle-row-collapse'}}
                                onClick={() => toggleOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        )}
                    </TableCell>    
                ))}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div className="rampup-floor-details-section-mobile">
                            <OccupancyChart 
                                maxOccupancy={props.data.capacity}
                                data={props.data.occupancyHistory}
                                weeklyAvgOccupancy={props.data.avgOccupancyHistory}
                                showHalfCapacityLine={false}
                                showMaxCapacityLine={true}
                                showCurrentOccupancyLegend={true}
                                isRestaurantClosed={props.data.isClosed}
                                message={props.data.message}
                            />
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default RestaurantOccupancyTableRow;