import React, { useState, useEffect, useRef } from 'react';
import ChartistGraph from 'react-chartist';
import Chartist from 'chartist';
import moment from 'moment';

import './OccupancyChart.scss';
import { BUILDING_CAPACITY_RANGES } from '../constants/capacityRanges.constants';
import HistoricalOccupancyRecord from '../models/HistoricalOccupancyRecord.interface';
import ChartDataPoint from './ChartDataPoint.class';

interface OccupancyChartProps {
    mobileVersion?: boolean;
    maxOccupancy: number;
    weeklyAvgOccupancy: ChartDataPoint[];
    data: HistoricalOccupancyRecord[];
    showHalfCapacityLine?: boolean;
    showMaxCapacityLine?: boolean;
    showCurrentOccupancyLegend?: boolean;
    isRestaurantClosed?: boolean;
    message?: string;
};

const OccupancyChart: React.FunctionComponent<OccupancyChartProps> = (props) => {
    const isFirstRun = useRef(true);
    const rangeStart = new Date();
    const rangeEnd = new Date();

    rangeStart.setHours(7);
    rangeStart.setMinutes(0);
    rangeStart.setSeconds(0);
    rangeStart.setMilliseconds(0);

    rangeEnd.setHours(19);
    rangeEnd.setMinutes(0);
    rangeEnd.setSeconds(0);
    rangeEnd.setMilliseconds(0);

    const parseOccupancyData = (data: HistoricalOccupancyRecord[], rangeStart: Date, rangeEnd: Date): ChartDataPoint[] => {
        return data
            .map(item => (new ChartDataPoint(item.count, item.timestamp)))
            .filter(point => (point.x >= rangeStart) && (point.x <= rangeEnd))
            .sort((pointA, pointB) => pointA.x.getTime() - pointB.x.getTime());
    }

    const parseWeeklyAvgData = (data: ChartDataPoint[], rangeStart: Date, rangeEnd: Date): ChartDataPoint[] => {
        return data
            .map(item => {
                item.x.setMonth(rangeStart.getMonth());
                item.x.setDate(rangeStart.getDate());
                return item;
            })
            .filter(point => (point.x >= rangeStart) && (point.x <= rangeEnd))
    }


    const [occupancyData, setOccupancyData] = useState<ChartDataPoint[]>(() => parseOccupancyData(props.data, rangeStart, rangeEnd));
    const [weeklyAvgData, setWeeklyAvgData] = useState<ChartDataPoint[]>(() => parseWeeklyAvgData(props.weeklyAvgOccupancy, rangeStart, rangeEnd));
    const lowCapacityLineTreshold = BUILDING_CAPACITY_RANGES.find(({ status }) => status === 'low').range[1];
    const criticalCapacityLineTreshold = BUILDING_CAPACITY_RANGES.find(({ status }) => status === 'critical').range[1];
    const lowCapacityLineY = (props.maxOccupancy / 100) * lowCapacityLineTreshold;
    const criticalCapacityLineY = (props.maxOccupancy / 100) * criticalCapacityLineTreshold;

    const getTimeTicks = () => {
        const hoursToShow = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

        return hoursToShow.map(hour => {
            const date = new Date();

            date.setHours(hour);
            date.setMinutes(0);
            date.setMilliseconds(0);

            return date;
        })
    }

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        setOccupancyData(parseOccupancyData(props.data, rangeStart, rangeEnd));
    }, [props.data]);

    useEffect(() => {
        setWeeklyAvgData(parseWeeklyAvgData(props.weeklyAvgOccupancy, rangeStart, rangeEnd));
    }, [props.weeklyAvgOccupancy]);

    const options: any = {
        showArea: true,
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0.1
          }),
        axisX: {
            type: Chartist.FixedScaleAxis,
            labelInterpolationFnc: (value) => moment(value).format('HH:mm'),
            ticks: getTimeTicks(),
            low: rangeStart.getTime(),
            high: rangeEnd.getTime()
        },
        axisY: {
            type: Chartist.AutoScaleAxis,
            low: 0,
            onlyInteger: true,
            scaleMinSpace: 20,
            high: Math.max.apply(null, [
                props.maxOccupancy, 
                ...occupancyData.map(event => event.y), 
                ...weeklyAvgData.map(event => event.y)
            ])
        },
        guidelines: []
    };

    if (props.showHalfCapacityLine !== false) {
        options.guidelines.push({
            value: lowCapacityLineY,
            className: 'ct-target-line low'
        });
    }

    if (props.showMaxCapacityLine !== false) {
        options.guidelines.push({
            value: criticalCapacityLineY,
            className: 'ct-target-line critical'
        });
    }

    const data = {
        series: [
            {
                name: 'peopleCount',
                data: occupancyData
            },
            {
                name: 'weeklyAvgOccupancy',
                data: weeklyAvgData,
                tension: 0
            }
        ]
    };

    function projectY(chartRect, bounds, value) {
        return chartRect.y1 - (chartRect.height() / bounds.max * value);
    }

    function drawLinesAtCreation(context) {
        if (!context?.options.guidelines) { return; }

        context.options.guidelines.forEach(lineOptions => {
            const targetLineY = projectY(context.chartRect, context.bounds, lineOptions.value);

            context.svg.elem('line', {
                x1: context.chartRect.x1,
                x2: context.chartRect.x2,
                y1: targetLineY,
                y2: targetLineY
            }, lineOptions.className);

            context.svg.elem('line', {
                x1: context.chartRect.x1,
                x2: context.chartRect.x2,
                y1: 190,
                y2: 190
            }, lineOptions.className);
        });
      }

    return occupancyData.length && !props.isRestaurantClosed ? (
        <article className="rampup-occupancy-chart">
            <React.Fragment>
                <ChartistGraph
                    className="rampup-occupancy-chart"
                    type="Line"
                    data={data}
                    options={options}
                    listener={{ created: drawLinesAtCreation }}>
                </ChartistGraph>
                <aside className="rampup-occupancy-chart-legend">
                    <ul>
                        {(props.showMaxCapacityLine !== false) && <li className="legend-max">Max</li>}
                        <li className="legend-weekly-avg">Weekly AVG</li>
                        {(props.showHalfCapacityLine !== false) && <li className="legend-low">50% of MAX</li>}
                        {(props.showCurrentOccupancyLegend) && <li className="legend-current-occupancy">Occupancy today</li>}
                    </ul>
                </aside>
            </React.Fragment>
        </article>
    ) : (
        <article className="rampup-occupancy-chart rampup-occupancy-chart-placeholder">
            {
                props.message !== null
                    ? props.message
                    : "Occupancy data is currently not available for this area"
            }

        </article>
    );
}

export default OccupancyChart;