import React from "react";
import RestoBar from "../RestoBar/RestoBar";
import './Statistics.scss';
import {CONFIG} from "../config/config";

const Statistics = () => {

    return (
        <React.Fragment>
            <RestoBar back={true}/>
            <iframe width="1260"
                    className="ga-frame"
                    height="1660"
                    src={CONFIG.ANALYTICS_URL}
                    frameBorder="0" allowFullScreen></iframe>
        </React.Fragment>
    );
}

export default Statistics;