import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const RocheIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon 
            className="rampup-roche-icon" 
            {...{ viewBox: '0 2 200 100', ...props }}>
            <g>
                <path d="M134.59 39.723c-1.252-.937-3.223-1.501-5.134-1.472-2.077.03-4.062.696-5.443 1.798V26.938h-6.486v42.758h6.486V47.377c0-1.556 1.304-3.27 3.721-3.373 1.077-.047 2.571.422 3.326 1.553.805 1.198.758 2.791.758 4.53l-.007 19.609h6.486V49.842c-.004-5.856-.766-7.94-3.707-10.119M81.838 54.405c0 4.183-.132 5.362-.165 5.725-.227 2.322-1.216 4.779-3.919 4.779-2.699 0-3.794-2.626-3.91-4.89-.008-.014-.17-1.431-.17-5.614 0-4.193.165-5.936.17-5.95.032-2.363 1.255-4.725 3.91-4.725 2.656 0 3.883 2.362 3.916 4.699.003.04.168 1.783.168 5.976m6.274-5.046c-.92-9.141-6.11-11.104-10.368-11.104-6.036 0-9.71 3.841-10.372 11.09-.059.578-.147 1.644-.18 5.06-.022 2.487.136 4.523.169 5.04.41 6.768 4.3 10.94 10.383 10.94 6.086 0 9.961-4.242 10.386-10.94.03-.476.168-2.553.168-5.04 0-1.128-.106-4.19-.186-5.046zM149.119 50.553c.047-1.414.047-1.714.077-2.124.183-2.56 1.325-4.68 3.951-4.7 3.11-.021 3.842 3.026 3.952 4.714.055.886.124 1.48.113 2.11h-8.093m8.01 8.57s.01.476-.063 1.054c-.183 1.51-.776 4.732-3.933 4.732-2.689 0-3.725-2.443-3.904-4.878-.033-.692-.162-1.465-.154-4.091h14.462s.026-2.153-.014-3.446c-.022-.51-.048-1.355-.169-3.139-.198-2.743-1.223-5.739-2.794-7.676-1.817-2.241-4.384-3.424-7.427-3.424-5.842 0-9.493 3.805-10.211 11.093-.066.652-.172 1.564-.172 5.057 0 2.487.14 4.567.165 5.04.392 6.658 4.329 10.94 10.218 10.94 5.874 0 9.811-4.172 10.221-11.262h-6.226z"/>
                <path d="M46.68 5.114h105.402l40.304 47.16-40.293 46.512H46.673L6.221 52.116 46.68 5.115M154.63-.123H44.135L-.7 51.952l44.838 51.741h110.489L199.3 52.12 154.63-.123z"/>
                <path d="M47.983 32.743h3.07c3.336 0 5.145 1.769 5.226 4.93.007.23.033.497.033.988 0 .575-.008.78-.03 1-.187 1.879-1.01 5.153-4.918 5.153h-3.38V32.743m15.36 34.305l-.667-10.496c-.29-4.696-1.513-7.464-3.941-8.882 1.754-1.054 4.025-3.666 4-9.115-.03-8.303-5.183-11.214-11.248-11.28h-9.91v42.42h6.402l.003-19.406h3.564c2.062 0 4.384 1.802 4.735 6.867l.667 9.932c.055 1.406.322 2.611.322 2.611h6.405c-.003 0-.278-1.172-.333-2.651zM107.057 58.83c-.026.468-.059.89-.08 1.19-.184 2.37-1.015 4.889-3.912 4.889-2.703 0-3.769-2.732-3.966-4.743-.118-1.223-.088-2.168-.114-5.76-.03-4.194.106-5.27.165-5.952.23-2.651 1.256-4.706 3.915-4.724 2.732-.019 3.882 2.362 3.911 4.699 0 .025.059.59.1 1.83h6.416c-.022-.428-.04-.783-.055-.992-.73-8.866-6.127-11.012-10.387-11.012-6.035 0-9.712 3.841-10.375 11.09-.059.578-.147 1.644-.176 5.06-.03 2.487.128 4.523.165 5.04.414 6.768 4.3 10.925 10.386 10.94 6.303.021 9.782-3.824 10.387-10.94.018-.26.055-.44.036-.615h-6.416"/>
            </g>
        </SvgIcon>
    )
};

export default RocheIcon;