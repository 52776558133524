import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';

import './SiteRestaurantListMobile.scss';

import TableColumn from '../uiToolkit/RampUpTable/TableColumn.interface';
import useRestaurantListTableData from './useRestaurantListTableData';
import RestaurantListTable from './RestaurantListTable/RestaurantListTable';
import { RestaurantAggregatedData } from '../models/RestaurantAggregatedData.interface';
import SiteRestaurantData from '../models/SiteRestaurantData.interface';


interface SiteRestaurantListMobileProps {
    restaurants: SiteRestaurantData[];
    restaurantsAggregatedData: RestaurantAggregatedData[];
}

const SiteRestaurantListMobile: React.FunctionComponent<SiteRestaurantListMobileProps> = (props) => {
    const { rows, setData } = useRestaurantListTableData(props.restaurants);

    const columns: TableColumn[] = [
        { caption: '', id: '', type: 'toggleRow', allowSorting: false, width: '10%' },
        { caption: 'Restaurant', id: 'restaurantName', type: 'siteCanteen', width: '35%' },
        { caption: 'Occupancy', id: 'occupancyPercentage', type: 'capacityPercentage', width: '23%' },
        { caption: 'Available seats', id: 'available', type: 'availablePlacesCount', align: 'right', width: '22%' }
    ];

    useEffect(() => {
        setData(props.restaurants);
    }, [props.restaurants])

    return (
        <div className="rampup-building-restaurants-table">
            <Typography color="textPrimary" variant="h6" component="h6" classes={{ root: "rampup-section-header mobile-restaurants-list-header" }}>
                Current Restaurants/Cafes Occupancy
            </Typography>
            <RestaurantListTable
                data={rows}
                columns={columns}
                keyColumn="restaurantId"
                restaurantsAggregatedData={props.restaurantsAggregatedData}
            />
        </div>
    ); 
    
}

export default SiteRestaurantListMobile;