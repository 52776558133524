import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { createMuiTheme, ThemeProvider, Box, withStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import './App.scss';
import RestoFooter from './RestoFooter/RestoFooter';
import SiteRestaurants from './SiteRestaurants/SiteRestaurants';
import RestoDashboard from './RestoDashboard/RestoDashboard';
import RestoTvDashboard from './RestoTvDashboard/RestoTvDashboard';
import {GA} from "./analytics/GA";
import Statistics from "./stats/Statistics";

GA.init();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0066cc'
    }
  },
  overrides: {
    MuiTableCell: {
      root: {
        '@media (max-width:959px)': {
          fontSize: '1rem'
        }
      }
    }
  }
});

const PageWrapper = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: '64px'
    }
  }
}))(Box);

function App() {  
  return (
    <ThemeProvider theme={theme}>
      <PageWrapper className="App">
        <SnackbarProvider maxSnack={5}>
          <HashRouter>
            <Switch>
              <Route exact path='/' component={RestoDashboard}/>
              <Route path='/tv' component={RestoTvDashboard}/>
              <Route path='/site/:siteId' component={SiteRestaurants}/>
              <Route path='/stats' component={Statistics}/>
              <Route path='*'>
                <Redirect to="/"/>
              </Route>
            </Switch>
          </HashRouter>

          <RestoFooter></RestoFooter>
        </SnackbarProvider>
      </PageWrapper>
    </ThemeProvider>
  );
}

export default App;
