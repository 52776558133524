import React from 'react';
import { Link, Container, Button } from '@material-ui/core';
import './RestoFooter.scss';

const RestoFooter: React.FunctionComponent = () => {
    return (
        <Container className="resto-footer">
            <div>
                <Button
                    href="#/stats">
                    Statistics
                </Button>
            </div>
            <div className="powered-by">
                Powered by TAVERO & <Link href="http://iris.roche.com" color="textSecondary">IRIS</Link>
            </div>
        </Container>
    );
}

export default RestoFooter;
