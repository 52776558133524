import React from 'react';
import { useEffect } from 'react';

import RestoDashboard from '../RestoDashboard/RestoDashboard';
import './RestoTvDashboard.scss';
import {GA} from "../analytics/GA";

const RESTO_TV_WRAPPER_CLASS = 'resto-tv';

const RestoTvDashboard = () => {
    useEffect(() => {
        GA.pageView("Resto TV dashboard");

        document.body.classList.add(RESTO_TV_WRAPPER_CLASS);

        return () => {
            document.body.classList.remove(RESTO_TV_WRAPPER_CLASS);
        };
    });

    return <RestoDashboard showMasterDetailView={false} />;
};

export default RestoTvDashboard;
