import React from 'react';
import { Typography } from '@material-ui/core';

import './SiteRestaurantOverview.scss';
import PeoplePerFloor from '../../uiToolkit/PeopleCount/PeopleCount';
import OccupancyChart from '../../OccupancyChart/OccupancyChart';
import HistoricalOccupancyRecord from '../../models/HistoricalOccupancyRecord.interface';
import ChartDataPoint from '../../OccupancyChart/ChartDataPoint.class';
import CurrentOccupancy from './CurrentOccupancy/CurrentOccupancy';

interface SiteRestaurantOverviewProps {
    name: string;
    buildingCode: string;
    floorName: string;
    capacity: number;
    currentOccupancy: number;
    historicalData: HistoricalOccupancyRecord[];
    weeklyAvgOccupancy: ChartDataPoint[];
    isClosed?: boolean;
    message?: string;
};

const SiteRestaurantOverview: React.FunctionComponent<SiteRestaurantOverviewProps> = (props) => {
    function getCurrentOccupancyValue() {
        return Math.round((props.currentOccupancy / props.capacity) * 100);
    }

    return (
        <React.Fragment>
            <div className="rampup-site-restaurant-section-status">
                <Typography color="textPrimary" variant="h6" component="h6" classes={{ root: "rampup-site-restaurant-header" }}>
                    <span className="rampup-site-restaurant-header-title">{ props.buildingCode } { props.name }</span>
                    { props.floorName ? <span className="rampup-site-restaurant-header-subtitle">{props.floorName}</span> : '' }
                </Typography>
                {
                    !props.isClosed && (<React.Fragment>
                        <div className="rampup-site-restaurant-section-status-count">
                            Available seats: <PeoplePerFloor capacity={props.capacity} count={props.currentOccupancy} showRemainingCapacity={true}></PeoplePerFloor>
                        </div> 
                        <div
                            className="rampup-site-restaurant-section-status-max-occupancy">
                            Max: {props.capacity}
                        </div>
                        <div
                            className="rampup-site-restaurant-section-status-current-occupancy">
                            <CurrentOccupancy 
                                value={getCurrentOccupancyValue()}
                                historicalData={props.historicalData}
                            />
                        </div>
                    </React.Fragment>)
                }
                {
                    props.isClosed && <div className="resto-badge-closed">Closed today</div>
                }
            </div>
            <div className="rampup-site-restaurant-section-history">
                <OccupancyChart 
                    maxOccupancy={props.capacity}
                    data={props.historicalData}
                    weeklyAvgOccupancy={props.weeklyAvgOccupancy}
                    showHalfCapacityLine={false}
                    showMaxCapacityLine={true}
                    showCurrentOccupancyLegend={true}
                    isRestaurantClosed={props.isClosed}
                    message={props.message}
                />
            </div>
        </React.Fragment>
    );
}

export default SiteRestaurantOverview;