import ReactGA from "react-ga";
import {CONFIG} from "../config/config"


export const GA = function () {
    return {
        init: () => ReactGA.initialize(CONFIG.ANALITICS_ID),

        pageView: (page) => {
            ReactGA.pageview(page);
        },

        event: (categoryName, eventName, label) => {
            ReactGA.event({
                category: categoryName,  // Required
                action: eventName,       // Required
                label: label,
                nonInteraction: false
            });
        },

        timing: (categoryName, variableName, valueNum) => {
            ReactGA.timing({
                category: categoryName,
                variable: variableName,
                value: valueNum
            });
        }
    }
}();