import React, { useState, useEffect } from 'react';
import { withStyles, CircularProgress, Container, Typography, Button, Hidden, IconButton } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ArrowBack } from '@material-ui/icons';

import './SiteRestaurants.scss';
import { getRestaurantAggregatedData, getRestaurantsBySiteId, getSite } from '../api/server';
import useInterval from '../common/useInterval';
import SiteDetailsData from '../models/SiteDetailsData.interface';
import SiteRestaurantList from './SiteRestaurantList/SiteRestaurantList';
import SiteRestaurantListMobile from '../SiteRestaurantListMobile/SiteRestaurantListMobile';
import { RestaurantAggregatedData } from '../models/RestaurantAggregatedData.interface';
import SiteRestaurantData from '../models/SiteRestaurantData.interface';
import RestoBar from '../RestoBar/RestoBar';
import {GA} from "../analytics/GA";

const DetailPageCircularProgress = withStyles({
    root: { marginTop: '2em' }
})(CircularProgress);

interface SiteRestaurantsState {
    dateLastRefreshed: Date;
    site: SiteDetailsData;
    restaurants: SiteRestaurantData[];
    restaurantsAggregatedData: RestaurantAggregatedData[];
}

const SiteRestaurants = () => {
    const { siteId } = useParams<any>();
    const { enqueueSnackbar } = useSnackbar();
    const [state, setState] = useState({
        dateLastRefreshed: null,
        site: null,
        restaurants: null,
        restaurantsAggregatedData: null,
        gaUpdated: false
    });

    const getRestaurantsAggregatedData = (restaurants: SiteRestaurantData[]): Promise<RestaurantAggregatedData[]> => {
        const restaurantsIDs = restaurants.map(restaurant => restaurant.cateringOutletId);

        return Promise.all(restaurantsIDs.map(id =>  getRestaurantAggregatedData(id)))
            .then(data => data.reduce((allRestaurants, restaurant) => allRestaurants.concat([restaurant]), []));
    };

    const updateData = () => {
        const id: number = Number(siteId);

        return Promise.all([
                getRestaurantsBySiteId(id),
                getSite(id)
            ])
            .then(data => {
                if (!state.gaUpdated) {
                    GA.pageView("Restaurants for " + data[1].name);
                }
                return getRestaurantsAggregatedData(data[0]).then(restaurantsAggregatedData => {
                    setState({
                        dateLastRefreshed: new Date(),
                        site: data[1],
                        restaurants: data[0],
                        restaurantsAggregatedData,
                        gaUpdated: true
                    });
                });
            })
            .catch(e => {
                console.error(e);

                enqueueSnackbar('Failed to fetch building details!', {
                    variant: 'error',
                    anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'top'
                    }
                });
            });
    }

    useEffect(() => {
        updateData();
    }, []);

    useInterval(() => {
        updateData();
    }, 60000);

    return (
        <React.Fragment>
            <RestoBar dateLastRefreshed={state.dateLastRefreshed}></RestoBar>
            { !state.dateLastRefreshed && <Container>
                <DetailPageCircularProgress/>
            </Container> }

            { state.dateLastRefreshed && state.site && <Container>
                <Hidden only={['xs', 'sm']}>
                    <Button
                        classes={{ root: 'back-button' }}
                        startIcon={<ArrowBack/>}
                        href="#/resto"
                        size="small">
                        Back to Homepage
                    </Button>
                </Hidden>

                <Hidden only={['md', 'lg', 'xl']}>
                    <IconButton classes={{ root: 'back-button-mobile' }} href="#/resto" size="medium">
                        <ArrowBack/>
                    </IconButton>
                </Hidden>

                <Typography color="textPrimary" variant="h4" component="h4" classes={{ root: "rampup-building-header" }}>
                    {state.site.name}
                </Typography>

                <Hidden only={['xs', 'sm']}>
                {
                    state.restaurants.length ? (<SiteRestaurantList
                        restaurants={state.restaurants}
                        restaurantsAggregatedData={state.restaurantsAggregatedData}
                    />) : null
                }
                </Hidden>
                <Hidden only={['md', 'lg', 'xl']}>
                {
                    state.restaurants.length ? (<SiteRestaurantListMobile
                        restaurants={state.restaurants}
                        restaurantsAggregatedData={state.restaurantsAggregatedData}
                    />) : null
                } 
                </Hidden>
                
            </Container> }

            { state.dateLastRefreshed && !state.site && <Container>               
                <Typography color="textPrimary" variant="h4" component="h4" classes={{ root: "rampup-building-header" }}>
                    No data found
                </Typography>

                <Typography color="textPrimary" variant="body1" component="p">
                    Sorry, no data was found for specified site.
                </Typography>
            </Container> }
        </React.Fragment>
    )
}

export default SiteRestaurants;