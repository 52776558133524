
const CONFIG_DEV = {
    ANALITICS_ID: 'UA-176419454-4',
    ANALYTICS_URL: 'https://datastudio.google.com/embed/reporting/65cccc04-da54-497d-88f6-7630b7a17076/page/sUehC'
}

const CONFIG_TEST = {
    ANALITICS_ID: 'UA-176419454-6',
    ANALYTICS_URL: 'https://datastudio.google.com/embed/reporting/20aa4fad-742c-40ff-b61f-e47d07da2005/page/sUehC'
}

const CONFIG_PROD = {
    ANALITICS_ID: 'UA-176419454-5',
    ANALYTICS_URL: 'https://datastudio.google.com/embed/reporting/7b89d2fd-d6d0-4bca-8662-f862b7fb8547/page/sUehC'
};

export const CONFIG = function () {
    switch (window.location.hostname) {
        case "localhost":
        case "iris-dev.roche.com":
        case "rbalvjws5s1a.bas.roche.com":
        case "inwo-dev.roche.com":
            return CONFIG_DEV;
        case "iris-test.roche.com":
        case "inwo-test.roche.com":
        case "rbalvjws5u1a.bas.roche.com":
            return CONFIG_TEST;
        case "iris.roche.com":
        case "inwo.roche.com":
        case "resto.roche.com":
            return CONFIG_PROD;
    }
}();