import { useState } from 'react';

import HistoricalOccupancyRecord from '../models/HistoricalOccupancyRecord.interface';
import ChartDataPoint from '../OccupancyChart/ChartDataPoint.class';

export default function useOccupancyTrend(data: HistoricalOccupancyRecord[]) {
    const [trendStatus, setTrendStatus] = useState(getTrendStatus(data));
    
    function getTrendStatus(data: HistoricalOccupancyRecord[]): number {
        if (data) {
            const sampleLengthInMinutes = 10;
            const occupancyData = data
                .map(event => (new ChartDataPoint(event.count, event.timestamp)))
                .sort((pointA, pointB) => pointA.x.getTime() - pointB.x.getTime());

            const lastRecord = occupancyData[occupancyData.length - 1];

            if (!lastRecord) {
                return 0;
            }

            const msPerMinute = 60000;
            const dateFrom = new Date(lastRecord.x.valueOf() - (msPerMinute * sampleLengthInMinutes));

            // calculate the slope of linear regression
            const values = occupancyData
                .filter(point => point.x >= dateFrom)
                .map(point => point.y);

            const indexes = values.map((item, index) => index + 1);
            const valuesTimesIndexes = values.map((value, index) => (value * (index + 1)));
            const indexesSquared = indexes.map(i => i * i);
            const sumOfValues = values.reduce((a, b) => a + b);
            const sumOfIndexes = indexes.reduce((a, b) => a + b);
            const sumOfValuesTimesIndexes = valuesTimesIndexes.reduce((a, b) => a + b);
            const sumOfIndexesSquared = indexesSquared.reduce((a, b) => a + b);
            const y = (values.length * sumOfValuesTimesIndexes) - (sumOfIndexes * sumOfValues);
            const x = (values.length * sumOfIndexesSquared) - (sumOfIndexes * sumOfIndexes);
            const trendValue = y / x;

            return trendValue;
        }
        return undefined;
    }

    return {
        value: trendStatus,
        setValue: (data: HistoricalOccupancyRecord[]) => setTrendStatus(getTrendStatus(data))
    };
};
