import React from 'react';
import { TableRow, TableHead, TableCell, TableSortLabel } from '@material-ui/core';

import TableColumn from '../TableColumn.interface';
import { EventSeat, Fastfood, Group } from '@material-ui/icons';

interface TableHeaderProps {
    columns: TableColumn[];
    onApplySorting: (columnId: string) => void;
    orderBy: string;
    sortOrder: 'asc' | 'desc';
}

const RampUpTableHeader: React.FunctionComponent<TableHeaderProps> = (props) => {
    const { orderBy, sortOrder } = props;

    const getIcon = (iconName: string) => {
        switch(iconName) {
            case 'FastFood':
                return <Fastfood fontSize="small"/>;
            case 'Group':
                return <Group fontSize="small"/>;  
            case 'EventSeat':
                return <EventSeat fontSize="small"/>;
        }

        return null;
    }

    return (
        <TableHead>
            <TableRow>
                {props.columns.map(column => {
                    return (
                        <TableCell
                            key={column.id}
                            align={(column.type === 'number') || (column.align === 'right') ? 'right' : 'left'}>
                            { (column.allowSorting !== false) && <TableSortLabel 
                                onClick={() => props.onApplySorting(column.id)}
                                active={column.id === orderBy}
                                direction={column.id === orderBy ? sortOrder : 'asc'}>
                                {column.align === 'right' && getIcon(column.headerIcon)}
                                {column.caption}
                                {column.align !== 'right' && getIcon(column.headerIcon)}
                            </TableSortLabel> }
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    )
};

export default RampUpTableHeader;