import React, {useEffect, useRef, useState} from "react";
import './TrendArrow.scss';
import {BuildingCapacityStatus} from "../../../models/BuildingCapacityStatus.type";

interface TrendArrowProps {
    status: BuildingCapacityStatus;
    trend: number;
}

const TrendArrow: React.FunctionComponent<TrendArrowProps> = props => {

    const [ status, setStatus ] = useState(props.status);
    const [ trend, setTrend ] = useState(props.trend)
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) {
            setStatus(props.status);
            setTrend(props.trend);
        } else {
            didMount.current = true;
        }
    }, [props.status, props.trend])

    return (<div className="resto-current-occupancy-trend">
        { trend > 0 && <div className={`trend-arrow ${status}`}></div> }
        { trend < 0 && <div className={`trend-arrow trend-arrow-down`}></div> }
    </div>);
}

export default TrendArrow;