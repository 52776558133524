import React, { useEffect } from 'react';
import { Table, TableContainer, Paper, TableBody } from '@material-ui/core';

import TableColumn from '../../uiToolkit/RampUpTable/TableColumn.interface';
import useSortableData from '../../uiToolkit/RampUpTable/useSortableData';
import RampUpTableHeader from '../../uiToolkit/RampUpTable/RampUpTableHeader/RampUpTableHeader';
import RestaurantListTableRow from './RestaurantListTableRow';
import RestaurantListTableData from '../RestaurantListTableData.interface';
import ChartDataPoint from '../../OccupancyChart/ChartDataPoint.class';
import { RestaurantAggregatedData } from '../../models/RestaurantAggregatedData.interface';

interface RestaurantListTableProps {
    data: RestaurantListTableData[];
    columns: TableColumn[];
    keyColumn: string;
    stickyHeader?: boolean;
    restaurantsAggregatedData: RestaurantAggregatedData[];
}

const RestaurantListTable: React.FunctionComponent<RestaurantListTableProps> = (props) => {
    const { items, sortConfig, doSort } = useSortableData<RestaurantListTableData>(props.data);

    const applySorting = (columnId: string) => {
        doSort(columnId);
    };

    const findRestaurantAggregatedData = (id: number): ChartDataPoint[] => {
        const restaurantData = props.restaurantsAggregatedData.find(data => data.cateringOutletId === id);

        return restaurantData ? restaurantData.avgOccupancy : [];
    }

    useEffect(() => {
        doSort('floorCode');
    }, []);

    return (
        <TableContainer component={Paper}> 
            <Table style={{ tableLayout: 'fixed' }}>
                <colgroup>
                    {
                        props.columns.map((col) => {
                            return <col key={col.id} style={{ width: col.width }}></col>;
                        })
                    }
                </colgroup>
                <RampUpTableHeader
                    columns={props.columns}
                    orderBy={sortConfig?.sortBy}
                    sortOrder={sortConfig?.sortOrder}
                    onApplySorting={(e) => applySorting(e)}>
                </RampUpTableHeader>
                <TableBody>
                    {items.map((row) => (
                        <RestaurantListTableRow 
                            key={row.cateringOutletId}
                            columns={props.columns}
                            rowKey={row.cateringOutletId}
                            data={row} 
                            historicalData={row.occupancyHistory}
                            weeklyAvgOccupancy={findRestaurantAggregatedData(row.cateringOutletId)}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default RestaurantListTable;