import React, { useEffect } from 'react';
import { LinearProgress, withStyles } from '@material-ui/core';
import useBuildingCapacity from '../useBuildingCapacity';

import './CapacityBar.scss';
import TrendArrow from "../../SiteRestaurants/SiteRestaurantOverview/TrendArrow/TrendArrow";
import HistoricalOccupancyRecord from "../../models/HistoricalOccupancyRecord.interface";
import useOccupancyTrend from "../useOccupancyTrend";

interface CapacityBarProps {
    value: number,
    occupancyHistory?: HistoricalOccupancyRecord[],
    isClosed?: boolean
}

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5
    },
}))(LinearProgress);

const CapacityBar: React.FunctionComponent<CapacityBarProps> = (props) => {
    const {value, capacityStatus, setValue} = useBuildingCapacity(props.value);
    const { value: trendValue, setValue: setTrendValue } = useOccupancyTrend(props.occupancyHistory);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    if (props.isClosed) {
        return (
            <div className="rampup-capacity-bar-container rampup-capacity-bar-container-restaurant-closed">
                <div className="rampup-capacity-bar">
                    <span className="text-muted">0%</span>
                    <BorderLinearProgress variant="determinate" value={0}/>
                </div>
            </div>
        );
    } else {
        return (
            value !== null ? <div className="rampup-capacity-bar-container">
                    <div className="rampup-capacity-bar">
                        <span className={`rampup-capacity-${capacityStatus}`}>{value}%</span>
    
                        <BorderLinearProgress
                            classes={{
                                bar: `rampup-capacity-${capacityStatus}`
                            }}
                            variant="determinate"
                            value={value > 100 ? 100 : value}/>
                    </div>
                    <div className="rampup-capacity-trend">
                        <TrendArrow status={capacityStatus} trend={trendValue}/>
                    </div>
                </div>
                : <div></div>
        );
    }

    
}

export default CapacityBar;