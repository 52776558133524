import React from 'react';
import moment from 'moment';

import { AppBar, withStyles, Toolbar, Grid, Typography, Button, Tooltip, Box, Link } from '@material-ui/core';
import RocheIcon from '../icons/RocheIcon';
import './RestoBar.scss';

const StyledAppBar = withStyles(theme => ({
    root: {
        backgroundColor: 'white',
        [theme.breakpoints.down('xs')]: {
            position: 'relative'
        }
    }
}))(AppBar)

interface RestoBarProps {
    dateLastRefreshed?: Date
    back?: boolean
}

const RestoBar: React.FunctionComponent<RestoBarProps> = (props) => {
    const formatDate = (date: Date): string => {
        return date ? moment(date).format('MMMM Do YYYY, HH:mm') : '';
    };

    return (
        <StyledAppBar position="fixed">
            <Toolbar>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography 
                            variant="h5" 
                            color="primary"
                            classes={{
                                root: 'rampup-main-header'
                            }}>
                            <Link href="#">
                                RestO 
                                <Box 
                                    style={{ fontWeight: 'normal', marginLeft: '0.25em' }} 
                                    display={{ xs: 'none', sm: 'none', md: 'inline-block' }}>
                                    Restaurants Occupancy
                                </Box>
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item>
                        {props.dateLastRefreshed && (
                            <Tooltip
                                title="Time of last data refresh" 
                                arrow>
                                <Typography className="rampup-time-updated" component="span" color="textSecondary">
                                    {formatDate(props.dateLastRefreshed)}
                                </Typography>
                            </Tooltip>
                        )}

                        {props.back && (
                            <Button
                                href="#/">
                                Back
                            </Button>
                        )}

                        <Button href="https://roche.com" target="_blank" className="rampup-roche-button">
                            <RocheIcon color="primary" style={{ width: '2.5em', height: '1.5em' }}/>
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        </StyledAppBar>
    );
}

export default RestoBar;