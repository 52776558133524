import { useState, useMemo } from "react";
import TableColumn from "./TableColumn.interface";

type SortOrder = 'asc' | 'desc';

interface SortableDataConfig {
    sortOrder: SortOrder;
    sortBy: string;
}

export default function useSortableData<T = {[key: string]: any}>(items: T[], config: SortableDataConfig = null, columnsConfig: TableColumn[] = []) {
    const [sortConfig, setSortConfig] = useState(config);
    const [originalItems, setOriginalItems] = useState(items);
    const [columns] = useState(columnsConfig);

    const sortedItems = useMemo<T[]>(() => {
        let sortableItems = [...originalItems];

        if (sortConfig === null) { return sortableItems };

        const { sortBy, sortOrder } = sortConfig;
        const sortColumn = columns.find(({ id }) => sortBy === id);
        const sortFields = sortColumn?.applySortingFor || [sortBy];

        sortableItems.sort((a, b) => {
            for (let i = 0; i < sortFields.length; i++) {
                if (a[sortFields[i]] < b[sortFields[i]]) {
                    return sortOrder === 'asc' ? -1 : 1;
                } else if (a[sortFields[i]] > b[sortFields[i]]) {
                    return sortOrder === 'asc' ? 1 : -1;
                }
            }

            return 0;
        });

        return sortableItems;
    }, [originalItems, sortConfig]);

    const doSort = (sortBy: string) => {
        let sortOrder: SortOrder = 'asc';

        if (sortConfig && sortConfig.sortBy === sortBy && sortConfig.sortOrder === 'asc') {
            sortOrder = 'desc';
        }

        setSortConfig({ sortBy, sortOrder });
    };

    return {
        items: sortedItems,
        setItems: setOriginalItems,
        sortConfig,
        doSort
    };
};