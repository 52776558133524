import 'whatwg-fetch';
import moment from 'moment';

import EndpointConfig from './EndpointConfig.class';
import { endpoints } from '../constants/api.constants';
import ChartDataPoint from '../OccupancyChart/ChartDataPoint.class';
import { RawRestaurantAggregatedData } from '../models/RawRestaurantAggregatedData.interface';
import { RestaurantAggregatedData } from '../models/RestaurantAggregatedData.interface';
import SiteDetailsData from '../models/SiteDetailsData.interface';
import RawSiteRestaurantData from '../models/RawSiteRestaurantData.interface';
import SiteRestaurantData from '../models/SiteRestaurantData.interface';
import RestaurantsOccupancyData from '../models/RestaurantOccupancyData.interface';
import HistoricalOccupancyRecord from '../models/HistoricalOccupancyRecord.interface';

export function getSiteRestaurants(siteId: number) {
    return apiFetch<RawSiteRestaurantData[]>(endpoints.getSiteRestaurants, { siteId });
}

export function getSites() {
    return apiFetch<SiteDetailsData[]>(endpoints.getSites);
}

export function getSite(siteId: number) {
    return getSites()
        .then(sites => sites.find(site => site.siteId === siteId) || null);
}

export function getRestaurantsBySiteId(siteId: number): Promise<SiteRestaurantData[]> {
    return getSiteRestaurants(siteId)
        .then(restaurants => {
            return restaurants.map(({ cateringOutlet, occupancy , open, message}) => {
                const occupancyHistory = getOccupancyHistory(occupancy);

                return {
                    restaurantName: cateringOutlet.name,
                    restaurantCapacity: cateringOutlet.capacity,
                    restaurantOccupancy: getCurrentOccupancy(occupancyHistory),
                    cateringOutletId: cateringOutlet.cateringOutletId,
                    buildingCode: cateringOutlet.floor?.building?.code || '',
                    floorName: cateringOutlet.floor.name,
                    occupancyHistory,
                    isClosed: !open,
                    message: message
                };
            });
        });
}

export function getSitesAndRestaurants(): Promise<{ site: SiteDetailsData, restaurants: RestaurantsOccupancyData[] }[]> {
    return getSites()
        .then(sites => {
            return Promise.all(sites.map(({ siteId }) => getSiteRestaurants(siteId)))
                .then(sitesRestaurants => {
                    const restaurantIds = sitesRestaurants
                        .reduce((a, b) => a.concat(b), [])
                        .map(({ cateringOutlet: { cateringOutletId } }) => cateringOutletId);

                    return getRestaurantsAggregatedData(restaurantIds)
                        .then(aggregatedData => {
                            return sitesRestaurants.map((restaurants, index) => ({
                                site: sites[index],
                                restaurants: restaurants.map(restaurant => {
                                    const restaurantId = restaurant.cateringOutlet.cateringOutletId;
                                    const occupancyHistory = getOccupancyHistory(restaurant.occupancy);
                                    const avgOccupancyHistory = aggregatedData.find(data => data.cateringOutletId === restaurantId);
        
                                    return {
                                        buildingId: restaurant.cateringOutlet.floor.building.buildingId,
                                        buildingCode: restaurant.cateringOutlet.floor.building.code,
                                        capacity: restaurant.cateringOutlet.capacity,
                                        cateringOutletId: restaurantId,
                                        cateringOutletTypeId: restaurant.cateringOutlet.cateringOutletType.cateringOutletTypeId,
                                        cateringOutletTypeName: restaurant.cateringOutlet.cateringOutletType.name,
                                        name: restaurant.cateringOutlet.name,
                                        occupancy: getCurrentOccupancy(occupancyHistory),
                                        siteId: restaurant.cateringOutlet.floor.siteId,
                                        occupancyHistory,
                                        avgOccupancyHistory: avgOccupancyHistory?.avgOccupancy || [],
                                        isClosed: !restaurant.open,
                                        message: restaurant.message
                                    }
                                })
                            }));
                        });
                });
        });
}

export function getRestaurantAggregatedData(cateringOutletId: number): Promise<RestaurantAggregatedData> {
    const today = moment().format('YYYY-MM-DD');

    return apiFetch<RawRestaurantAggregatedData[]>(endpoints.getRestaurantAggregatedData, { cateringOutletId })
        .then(data => ({
            cateringOutletId,
            avgOccupancy: data
                .map(record => {
                    const startTime = record.cateringOutletAggregatesId.startTime;
                    const normalizedTime = today + startTime.substr(startTime.indexOf('T'));

                    return new ChartDataPoint(record.avgWeekOccupancy, normalizedTime);
                })
                .sort((pointA, pointB) => pointA.x.getTime() - pointB.x.getTime())
        }));
}

function isRestaurantClosed(restaurantName: string): boolean {
    const dayOfWeek = new Date().getDay();

    if (restaurantName === 'Avenue' && dayOfWeek === 5) { // closed on Fridays
        return true;
    } else if (restaurantName === 'Forum' && [1, 5].includes(dayOfWeek)) { // closed on Fridays and Mondays
        return true;
    } else {
        return false;
    }
}

function getOccupancyHistory(occupancy: { effectiveDateTime: string, occupancyCount: number }[]): HistoricalOccupancyRecord[] {
    return occupancy.map(data => ({ timestamp: data.effectiveDateTime, count: data.occupancyCount }));
}

function getCurrentOccupancy(occupancyHistory: HistoricalOccupancyRecord[]): number {
    const currentPeopleCount = occupancyHistory
        .map(event => (new ChartDataPoint(event.count, event.timestamp)))
        .sort((pointA, pointB) => pointA.x.getTime() - pointB.x.getTime())
        .pop();
    
    return currentPeopleCount ? currentPeopleCount.y : 0;
}

function apiFetch<T>(endpoint: EndpointConfig,
                     params?: { [paramName: string]: string | number },
                     queryParams?: { [paramName: string]: string | number }): Promise<T> {
    let url = getApiUrl(endpoint.url);
    let startTime = Date.now();
    if (params) {
        Object.keys(params).forEach(paramName => {
            url = url.replace(`{${paramName}}`, String(params[paramName]));
        });
    }

    if (queryParams) {
        url = url + generateQueryString(queryParams);
    }

    return fetch(url, { method: endpoint.method })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return Promise.reject();
            }
        })
}

function getServerUrl(relativeUrl: string) {
    return `${window.location.origin + (window.location.pathname + "/").replace("/index.html/", "")
        .replace("//", "")}/${relativeUrl}`;
}

function getApiUrl(relativeUrl: string): string {
    if (window.location.origin === 'http://localhost:3000') { // for local dev
        return `http://rbalvjws5s1a.bas.roche.com:15080/iris/api/${relativeUrl}`;
        // return `http://localhost:8080/api/${relativeUrl}`;
    } else {
        return getServerUrl(`api/${relativeUrl}`);
    }
}

function generateQueryString(params: { [paramName: string]: any }): string {
    const query = Object.keys(params)
        .map(paramName => `${encodeURIComponent(paramName)}=${encodeURIComponent(params[paramName])}`)
        .join('&');
    
    return query ? ('?' + query) : '';
}

function getRestaurantsAggregatedData (cateringOutletIds: number[]): Promise<RestaurantAggregatedData[]> {
    return Promise.all(cateringOutletIds.map(id => getRestaurantAggregatedData(id)))
        .then(data => data.reduce((allRestaurants, restaurant) => allRestaurants.concat([restaurant]), []));
}