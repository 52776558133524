import { BuildingCapacityStatus } from '../models/BuildingCapacityStatus.type';
import { WorkplaceAvailabilityStatus } from '../models/WorkplaceAvailabilityStatus.type';

export const BUILDING_CAPACITY_RANGES: { status: BuildingCapacityStatus, range: [number, number] }[] = [
    { status: 'low', range: [0, 40] },
    { status: 'medium', range: [41, 79] },
    { status: 'critical', range: [80, 100] }
];

export const WORKPLACE_AVAILABILITY_RANGES: { status: WorkplaceAvailabilityStatus, range: [number, number] }[] = [
    { status: 'high', range: [60, 100] },
    { status: 'medium', range: [21, 59] },
    { status: 'critical', range: [0, 20] }
];