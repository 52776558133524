import React, { useEffect } from 'react';
import useBuildingCapacity from '../useBuildingCapacity';

interface PeoplePerFloorProps {
    capacity: number;
    count: number;
    showRemainingCapacity?: boolean;
}

const PeoplePerFloor: React.FunctionComponent<PeoplePerFloorProps> = (props) => {
    const {capacityStatus, setValue} = useBuildingCapacity(getPercentage(props.capacity, notNegative(props.count)));

    useEffect(() => {
        setValue(getPercentage(props.capacity, notNegative(props.count)));
    }, [props.capacity, props.count])

    function getPercentage(capacity: number, count: number): number {
        return Math.round((notNegative(count) / capacity) * 100);
    }

    function notNegative(value: number): number {
        return value > 0 ? value : 0;
    }

    function getRemainingCapacity() {
        return props.capacity - notNegative(props.count);
    }

    return (
        <span 
            className={'rampup-capacity-level-' + capacityStatus}>
            {props.showRemainingCapacity ? getRemainingCapacity() : notNegative(props.count)}
        </span>
    )
};

export default PeoplePerFloor;
