import { useState } from 'react';

import { BUILDING_CAPACITY_RANGES } from '../constants/capacityRanges.constants';
import { BuildingCapacityStatus } from '../models/BuildingCapacityStatus.type';

export default function useBuildingCapacity(value: number) {
    const [capacityPercentage, setCapacityPercentage] = useState(notNegative(value));
    
    function getCapacityStatus(capacityPercentage: number): BuildingCapacityStatus {
        return BUILDING_CAPACITY_RANGES
            .find(({ range }) => {
                return capacityPercentage >= range[0] && capacityPercentage <= range[1];
            })
            ?.status || 'critical';
    }

    function notNegative(value: number): number {
        return value > 0 ? value : 0;
    }

    return {
        value: capacityPercentage,
        capacityStatus: getCapacityStatus(capacityPercentage),
        setValue: value => setCapacityPercentage(notNegative(value))
    };
};
